<template>
    <div class="user-info-form">
        <div>
            <CNInput v-model="formData.first_name" :invalid="!!validationErrors.first_name"
                     @blur="handleChangeField('first_name', $event)" maxlength="100" label="First Name"
                     :required="false"/>
            <CFormText v-if="validationErrors.first_name" :style="`color: ${validationErrors.first_name && 'red'}`">
                {{ validationErrors.first_name }}
            </CFormText>
        </div>

        <div>
            <CNInput v-model="formData.last_name" :invalid="!!validationErrors.last_name"
                     @blur="handleChangeField('last_name', $event)" maxlength="100" label="Last Name"
                     :required="false"/>
            <CFormText v-if="validationErrors.last_name" :style="`color: ${validationErrors.last_name && 'red'}`">
                {{ validationErrors.last_name }}
            </CFormText>
        </div>

        <div>
            <CNInput v-model="formData.email" :invalid="!!validationErrors.email"
                     @blur="handleChangeField('email', $event)" maxlength="150" label="Email"
                     :required="false"/>
            <CFormText v-if="validationErrors.email" :style="`color: ${validationErrors.email && 'red'}`">
                {{ validationErrors.email }}
            </CFormText>
        </div>

        <div>
            <CNInput v-model="formData.phone" :mask="CONF.MASK_FORMAT_PHONE" :invalid="!!validationErrors.phone"
                     @blur="handleChangeField('phone', $event)" max-length="100" label="Phone Number"
                     :required="false"/>
            <CFormText v-if="validationErrors.phone" :style="`color: ${validationErrors.phone && 'red'}`">
                {{ validationErrors.phone }}
            </CFormText>
        </div>

        <div>
            <CNSelect :caret="true" :searchable="true" :invalid="!!validationErrors.profession_id" :options="jobsList"
                      label="Select Job Title" v-model="formData.profession_id"
                      @change="handleChangeField('profession_id', $event)"/>
            <CFormText v-if="validationErrors.profession_id"
                       :style="`color: ${validationErrors.profession_id && 'red'}`">
                {{ validationErrors.profession_id }}
            </CFormText>
        </div>

        <div>
            <input class="autofill-prevent" type="text"/>
            <CNInputPassword :model-value="formData.password" :invalid="!!validationErrors.password || passwordInvalid"
                             @update:model-value="v => formData.password = v" label="Enter Password"
                             @blur="passwordTouched = true" @change="handleChangeField('password', $event)"/>
            <div class="user-info-form__validations">
                <div v-for="validator in passValidators" :key="validator.title"
                     :class="[`user-info-form__validations-item_${ validator.validator ? 'success' : 'failed' }`]"
                     class="user-info-form__validations-item">
                    <div class="user-info-form__validations-label"
                         :style="{ 'background': passwordTouched ? '' : 'silver' }">
                        <CIcon name="cilCheckAlt"/>
                    </div>
                    <span :style="{ 'color': passwordTouched ? '' : 'black' }">{{ validator.title }}</span>
                </div>
            </div>
        </div>

        <div>
            <CNInputPassword :model-value="formData.password_confirmation"
                             :invalid="!!validationErrors.password_confirmation"
                             @update:model-value="v => formData.password_confirmation = v" label="Confirm Password"
                             @blur="handleChangeField('password_confirmation', $event)"/>
            <CFormText v-if="validationErrors.password_confirmation"
                       :style="`color: ${validationErrors.password_confirmation && 'red'}`">
                {{ validationErrors.password_confirmation }}
            </CFormText>
        </div>

        <div v-if="prefillData.company" class="user-info-form__company">
            <h4>Company</h4>
            <div class="user-info-form__company-card">
                <Avatar class="user-info-form__company-logo" :display-name="[prefillData.company.name]"
                        size="70" :rounded="false" :src="prefillData.company.logo"/>
                <div class="user-info-form__company-info">
                    <span class="user-info-form__company-name">{{ prefillData.company.name }}</span>
                    <div class="user-info-form__company-stats">
                        <span>{{ prefillData.company.address }}</span>
                        <span><CIcon name="cilUser"/>{{ prefillData.company.employees_amount }}+</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="user-info-form__buttons">
            <LoadingButton color="primary" :loading="loading" :aria-disabled="loading" @click="submit">
                {{ submitButtonText }}
            </LoadingButton>
        </div>
    </div>
</template>

<script>
import CNInput from "@/components/ui/CNInput/CNInput.vue";
import validator from "@/utils/validator";
import rules from "@/utils/validator/rules";
import * as CONF from "@/utils/constants/config";
import CNSelect from "@/components/ui/CNSelect/CNSelect.vue";
import CNInputPassword from "@/components/ui/CNInputPassword/CNInputPassword.vue";
import Avatar from "@/components/Avatar.vue";
import Professions from "@/api/v2/endpoints/Professions";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
    name: "UserInfoForm",
    props: {
        prefillData: {
            type: Object,
            default: {}
        },
        apiValidationErrors: Object,
        submitButtonText: {
            type: String,
            default: 'Create Profile'
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {LoadingButton, Avatar, CNInputPassword, CNSelect, CNInput},
    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                phone: null,
                profession_id: null,
                password: '',
                password_confirmation: ''
            },

            professions: [],
            passwordTouched: false,
            selectedCompany: null,

            validator: {},
            validationErrors: {}
        }
    },
    computed: {
        CONF() {
            return CONF;
        },
        passValidators() {
            return [
                {
                    title: 'Contains lowercase and uppercase letters',
                    validator: /[a-z]/.test(this.formData.password) && /[A-Z]/.test(this.formData.password)
                },
                {
                    title: 'Contains at least one number',
                    validator: /\d/.test(this.formData.password)
                },
                {
                    title: 'Contains at least one symbol',
                    validator: /[!@#$%^&*()_{}\[\]/'`~<>\-+]/.test(this.formData.password)
                },
                {
                    title: 'At least 8 characters',
                    validator: this.formData.password.length >= 8
                }
            ]
        },
        passwordInvalid() {
            if (!this.passwordTouched) return false

            return !this.passValidators[0].validator ||
                !this.passValidators[1].validator ||
                !this.passValidators[2].validator ||
                !this.passValidators[3].validator
        },
        jobsList() {
            return this.professions
                .map(profession => ({
                    value: profession.id,
                    name: profession.title
                }))
        }
    },
    watch: {
        apiValidationErrors(errors) {
            this.validationErrors = errors;
        }
    },
    created() {
        this.fetchProfessions()
        this.setRules();
        this.prefill();
    },
    methods: {
        submit() {
            const validationResult = this.validator.validateAll(this.formData);

            if (!validationResult.hasErrors) {
                this.$emit('formFilled', this.formData);
            } else {
                this.validationErrors = validationResult.validationErrors;
            }
        },
        fetchProfessions() {
            Professions.index().then(response => this.professions = response.data);
        },
        handleChangeField(field, value) {
            this.validationErrors[field] = this.validator.validate(
                field,
                value,
            )
        },
        setRules() {
            this.validator = validator({
                first_name: [rules.required],
                last_name: [rules.required],
                email: [rules.required, rules.email],
                phone: [rules.required, rules.digitsPhone, rules.strMinPhone(14, 10)],
                profession_id: [rules.required],
                password: [rules.required],
                password_confirmation: [
                    rules.required,
                    rules.passwordConfirmation(() => this.formData.password),
                ],
            })
        },
        prefill() {
            this.formData = {...this.formData, ...this.prefillData};
        }
    },
}
</script>

<style lang="scss">
.user-info-form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    & .autofill-prevent {
        height: 0px;
        width: 0px;
        opacity: 0;
        position: absolute;
    }

    &__buttons {
        display: flex;
        gap: 24px;
    }

    &__company {
        background: #ABE7FA80;
        padding: 16px 24px;
        border-radius: 8px;

        & h4 {
            font-size: 20px;
            color: #000000;
            margin-bottom: 17px;
        }

        &-card {
            display: flex;
            gap: 32px;
            padding-top: 24px;
            border-top: 1px solid #2BB0E0;
            font-size: 14px;
            color: #1C262F;
        }

        &-name {
            display: block;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        &-stats {
            display: flex;
            flex-direction: column;
            font-size: 14px;
        }

        &-logo {
            border: 1px solid #9fafbc;
        }
    }

    &__validations {
        margin-top: 12px;

        &:has(> &-label_success) {
            color: green;
        }

        &-item {
            display: flex;
            gap: 8px;
            align-items: center;
            font-size: 12px;

            &_success {
                color: #0BBD99;

                & .user-info-form__validations-label {
                    background: #0BBD99;
                }
            }

            &_failed {
                color: #FF1F26;

                & .user-info-form__validations-label {
                    background: #FF1F26;
                }
            }
        }

        &-label {
            height: 10px;
            width: 10px;
            border-radius: 200px;
            background: silver;
            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
                height: 8px !important;
                width: 8px !important;
                color: white;
            }
        }
    }
}
</style>
