<template>
    <AuthFormContainer v-if="userInvitation" comment="Your Profile" title="Create Account - User Info">
        <div class="signup-form mb-4">
            <UserInfoForm :loading="loading" :prefill-data="userInfo" :api-validation-errors="userValidationErrors"
                          @form-filled="submit"/>
        </div>
    </AuthFormContainer>
    <Expired v-else-if="getFieldValidationError('token')"/>
</template>

<script>
import AuthFormContainer from "@/components/AuthFormContainer.vue";
import UserInfoForm from "@/components/Forms/SignUpForm/UserInfoForm.vue";
import UserInvitations from "@/api/v2/endpoints/UserInvitations";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import Expired from "@/views/Error/Expired.vue";

export default {
    name: 'UserSignUp',
    mixins: [apiErrorHandler],
    components: {
        Expired,
        UserInfoForm,
        AuthFormContainer,
    },
    data() {
        return {
            token: null,
            loading: false,
            userInvitation: null,
            userValidationErrors: {},
            userInfo: {},
        }
    },
    created() {
        this.token = this.$route.query.token;
        this.loadUserInvitation();
    },
    methods: {
        loadUserInvitation() {
            UserInvitations
                .token({token: this.token})
                .then(response => {
                    this.userInvitation = response.data;
                    this.defineUserInfoByUserInvitation()
                })
                .catch(response => this.handleApiError(response));
        },
        defineUserInfoByUserInvitation() {
            this.userInfo = {
                first_name: this.userInvitation.invited_user.first_name,
                last_name: this.userInvitation.invited_user.last_name,
                email: this.userInvitation.invited_user.email,
                profession_id: this.userInvitation.invited_user.profession_id,
                company: this.userInvitation.invited_user.company,
            };
        },
        submit(userData) {
            this.userValidationErrors = {};
            this.toggleLoading();
            this.clearAEH();

            UserInvitations
                .accept(this.defineSignUpData(userData))
                .then(() => this.goLoginPage())
                .catch(response => this.handleSubmitError(response))
                .finally(() => this.toggleLoading());
        },
        defineSignUpData(userData) {
            return {
                token: this.token,
                user: {
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    email: userData.email,
                    phone: userData.phone,
                    profession_id: userData.profession_id,
                    password: userData.password,
                    password_confirmation: userData.password_confirmation,
                }
            };
        },
        handleSubmitError(response) {
            this.handleApiError(response);

            this.userValidationErrors = this.exportRequestObjectErrors('user');
        },
        goLoginPage() {
            this.$router.push({
                name: 'Login',
                query: {
                    modal: 'invite-user-success',
                    message: 'Your profile has been successfully create!',
                }
            });
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    }
}
</script>

<style scoped></style>
